import {
  SelectDS,
  Spacer,
  TableDS,
  TextCapitalized,
  dateFormatterDayMonthYearLong2Digits,
  unreachable,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import {
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "../companiesSlice";

import { useParams } from "react-router-dom";
import {
  transactionsListAsync,
  selectTransactionsList,
} from "./transactionsSlice";
import { TransactionsList } from "./transactionsApi";

export const TransactionsTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const companiesList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const transactionsList = useAppSelector(selectTransactionsList);

  const [companySelected, setCompanySelected] = useState<string>("");

  const optionCompany = useMemo(
    () =>
      companiesList.map((company) => {
        return {
          label: company.name,
          value: company.uuid,
        };
      }),
    [companiesList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [companySelected, optionCompany],
  );

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [companiesListStatus, dispatch]);

  useEffect(() => {
    if (companySelected) {
      void dispatch(transactionsListAsync({ companyUuid: companySelected }));
    }
  }, [companySelected, dispatch]);

  const headers = {
    executionDate: {
      text: t("transactions.column.executionDate"),
    },
    driverName: {
      text: t("transactions.column.driverName"),
    },
    registrationNumber: {
      text: t("transactions.column.registrationNumber"),
    },
    merchantName: {
      text: t("transactions.column.merchantName"),
    },
    amount: {
      text: t("transactions.column.amount"),
    },
    category: {
      text: t("transactions.column.category"),
    },
    merchantCity: {
      text: t("transactions.column.merchantCity"),
    },
    driverPhone: {
      text: t("transactions.column.driverPhone"),
    },
    vehicleReference: {
      text: t("transactions.column.vehicleReference"),
    },
    status: {
      text: t("transactions.column.status"),
    },
  };

  if (params.tab !== "transactions") {
    return;
  }

  const render = (row: TransactionsList) => (key: keyof TransactionsList) => {
    switch (key) {
      case "amount":
        return row[key] + " €";
      case "executionDate":
        return dateFormatterDayMonthYearLong2Digits(new Date(row[key]));
      case "category":
        return (
          <TextCapitalized>
            {t(`transactions.category.${row[key]}`)}
          </TextCapitalized>
        );
      case "registrationNumber":
      case "merchantName":
      case "merchantCity":
      case "driverName":
      case "driverPhone":
      case "vehicleReference":
      case "status":
        return row[key];
    }
    unreachable(key);
  };

  return (
    <StyledContainer>
      <Spacer y={1} />
      <StyledHeader>
        <TextCapitalized>{t("transactions.select.company")}</TextCapitalized>
        <SelectDS
          label={""}
          value={valueOptionCompany}
          options={optionCompany}
          onChange={(selectedOption) =>
            setCompanySelected(selectedOption ? selectedOption.value : "")
          }
          allWidth
        />
      </StyledHeader>
      <Spacer y={1} />
      <TableDS<keyof TransactionsList, TransactionsList>
        data={transactionsList}
        headers={headers}
        render={render}
      />
      <Spacer y={2} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
`;
