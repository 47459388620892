import { RouteObject } from "react-router-dom";
import { AuthGoogle } from "../qg_front/authGoogle";
import { Home } from "../qg_front/home";
import { Dashboard } from "../qg_front/dashboard";
import { QrCode } from "../qg_front/qrCode";
import { Statements } from "../qg_front/statements";
import { ZipFolder } from "../qg_front/zipFolder";
import { TermsAndPolicy } from "../qg_front/termsAndPolicy";
import { SupportingDocuments } from "../qg_front/supportingDocuments";
import { Billings } from "../qg_front/billings";
import { CompaniesData } from "../qg_front/companiesData";

export const routes: RouteObject[] = [
  {
    path: "/login",
    element: <AuthGoogle />,
  },
  {
    path: "home",
    element: <Home />,
    children: [
      { path: "", element: <Dashboard /> },
      { path: "qrCode", element: <QrCode /> },
      { path: "statements/:tab?", element: <Statements /> },
      { path: "zipFolder", element: <ZipFolder /> },
      { path: "termsAndPolicy", element: <TermsAndPolicy /> },
      { path: "supporting_documents/:id?", element: <SupportingDocuments /> },
      { path: "billings/:tab?", element: <Billings /> },
      { path: "companies_data/:tab?", element: <CompaniesData /> },
    ],
  },
  {
    path: "*",
    element: <AuthGoogle />,
  },
];
