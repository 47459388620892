import {
  ButtonDS,
  DownloadOutlined,
  SelectDS,
  Spacer,
  TableDS,
  TextCapitalized,
  triggerToast,
  unreachable,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  bankDetailsDownloadAsync,
  bankDetailsExportAsync,
  companyAccountDataAsync,
  selectBankDetailsExportLink,
  selectBankDetailsExportStatus,
  selectCompanyAccountData,
  selectCompanyCardsData,
} from "./accountDataSlice";
import {
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "../companiesSlice";
import {
  CompanyAccountDataDisplayed,
  CompanyCardsDataDisplayed,
} from "./accountDataApi";

export const DataTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const accountDataList = useAppSelector(selectCompanyAccountData);
  const cardsDataList = useAppSelector(selectCompanyCardsData);
  const companiesList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const bankDetailsExportStatus = useAppSelector(selectBankDetailsExportStatus);
  const bankDetailsExportLink = useAppSelector(selectBankDetailsExportLink);

  const [companySelected, setCompanySelected] = useState<string>("");

  const optionCompany = useMemo(
    () =>
      companiesList.map((company) => {
        return {
          label: company.name,
          value: company.uuid,
        };
      }),
    [companiesList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [companySelected, optionCompany],
  );

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [companiesListStatus, dispatch]);

  useEffect(() => {
    if (companySelected) {
      void dispatch(companyAccountDataAsync({ companyUuid: companySelected }));
    }
  }, [companySelected, dispatch]);

  useEffect(() => {
    if (bankDetailsExportStatus === "success") {
      triggerToast(t("accountData.bankDetails.upload.success") || "", "valid");
    } else if (bankDetailsExportStatus === "failed") {
      triggerToast(t("accountData.bankDetails.upload.failure") || "", "error");
    }
  }, [bankDetailsExportStatus, t]);

  useEffect(() => {
    if (bankDetailsExportLink.presignedUrl && bankDetailsExportLink.fileName) {
      void dispatch(bankDetailsDownloadAsync(bankDetailsExportLink));
    }
  }, [bankDetailsExportLink, dispatch]);

  const accountHeaders = {
    name: {
      text: t("accountData.column.name"),
    },
    iban: {
      text: t("accountData.column.iban"),
    },
    bic: {
      text: t("accountData.column.bic"),
    },
    address: {
      text: t("accountData.column.address"),
    },
    bankDetailsBucketFileName: {
      text: t("accountData.column.bankDetails"),
    },
  };

  const cardsHeaders = {
    totalCardsCount: {
      text: t("accountData.column.totalCardsCount"),
    },
    activeCardsCount: {
      text: t("accountData.column.activeCardsCount"),
    },
    inOppositionCardsCount: {
      text: t("accountData.column.inOppositionCardsCount"),
    },
  };

  if (params.tab !== "accountData") {
    return;
  }

  const accountDataRender =
    (row: CompanyAccountDataDisplayed) =>
    (key: keyof CompanyAccountDataDisplayed) => {
      switch (key) {
        case "bankDetailsBucketFileName":
          return (
            row[key] && (
              <ButtonDS
                sizeButton={"S"}
                format={"hug"}
                buttonType={"secondary"}
                singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
                onClick={() => {
                  void dispatch(
                    bankDetailsExportAsync({
                      bucketFileName: row[key] ?? "",
                      fileName: "Qivia_RIB.pdf",
                    }),
                  );
                }}
              />
            )
          );
        case "iban":
        case "bic":
          return row[key] ?? "-";
        case "address":
        case "name":
          return row[key];
      }
      unreachable(key);
    };

  const cardsDataRender =
    (row: CompanyCardsDataDisplayed) =>
    (key: keyof CompanyCardsDataDisplayed) => {
      switch (key) {
        case "activeCardsCount":
        case "inOppositionCardsCount":
        case "totalCardsCount":
          return row[key] ?? "-";
      }
      unreachable(key);
    };

  return (
    <StyledContainer>
      <Spacer y={1} />
      <StyledHeader>
        <TextCapitalized>{t("accountData.select.company")}</TextCapitalized>
        <SelectDS
          label={""}
          value={valueOptionCompany}
          options={optionCompany}
          onChange={(selectedOption) =>
            setCompanySelected(selectedOption ? selectedOption.value : "")
          }
          allWidth
        />
      </StyledHeader>
      <Spacer y={1} />
      <TableDS<keyof CompanyAccountDataDisplayed, CompanyAccountDataDisplayed>
        data={[accountDataList]}
        headers={accountHeaders}
        render={accountDataRender}
      />
      <Spacer y={2} />
      <TableDS<keyof CompanyCardsDataDisplayed, CompanyCardsDataDisplayed>
        data={[cardsDataList]}
        headers={cardsHeaders}
        render={cardsDataRender}
      />
      <Spacer y={2} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
`;
