import {
  ButtonDS,
  NumericInputDS,
  SelectDS,
  Spacer,
  TableDS,
  TextCapitalized,
  dateFormatterDayLongMonthYearHourMinuteSecond,
  triggerToast,
  unreachable,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  createPricingAsync,
  getPricingsListAsync,
  selectCreatePricingStatus,
  selectPricingsList,
} from "./pricingSlice";
import { PricingsList } from "./pricingApi";
import {
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "./../companiesSlice";
import { useParams } from "react-router-dom";

export const PricingTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const [pricePerCard, setPricePerCard] = useState<number | "">(0);
  const [percentagePerTransaction, setPercentagePerTransaction] = useState<
    number | ""
  >(0);
  const companyList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const createPricingStatus = useAppSelector(selectCreatePricingStatus);
  const pricingsList = useAppSelector(selectPricingsList);
  const [companySelected, setCompanySelected] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);

  const isValidSubmission = useMemo(
    () =>
      companySelected !== "" &&
      pricePerCard !== "" &&
      percentagePerTransaction !== "",
    [companySelected, percentagePerTransaction, pricePerCard],
  );

  const optionCompany = useMemo(
    () =>
      companyList.map((company) => {
        return {
          value: company.uuid,
          label: company.name,
        };
      }),
    [companyList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [optionCompany, companySelected],
  );

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [dispatch, companiesListStatus]);

  useEffect(() => {
    if (companySelected) {
      void dispatch(getPricingsListAsync({ company: companySelected }));
    }
  }, [companySelected, dispatch, createPricingStatus]);

  useEffect(() => {
    if (createPricingStatus === "success") {
      triggerToast(t("pricing.creation.success") || "", "valid");
    } else if (createPricingStatus === "failed") {
      triggerToast(t("pricing.creation.failure") || "", "error");
    }
  }, [createPricingStatus, t, dispatch]);

  const clickToSubmit = useCallback(() => {
    if (!isValidSubmission) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      void dispatch(
        createPricingAsync({
          company: companySelected,
          pricePerCard: pricePerCard === "" ? 0 : pricePerCard,
          percentagePerTransaction:
            percentagePerTransaction === "" ? 0 : percentagePerTransaction,
        }),
      );
    }
  }, [
    companySelected,
    dispatch,
    isValidSubmission,
    percentagePerTransaction,
    pricePerCard,
  ]);

  const headers = {
    company: {
      text: t("pricing.column.company"),
    },
    pricePerCard: {
      text: t("pricing.column.pricePerCard"),
    },
    percentagePerTransaction: {
      text: t("pricing.column.percentagePerTransaction"),
    },
    createdAt: {
      text: t("pricing.column.createdAt"),
    },
    creator: {
      text: t("pricing.column.creator"),
    },
  };

  if (params.tab !== "pricings") {
    return;
  }

  const render = (row: PricingsList) => (key: keyof PricingsList) => {
    switch (key) {
      case "company":
        return valueOptionCompany?.label;
      case "createdAt":
        return dateFormatterDayLongMonthYearHourMinuteSecond(
          new Date(row[key]),
        );
      case "pricePerCard":
        return row[key] + " €";

      case "percentagePerTransaction":
        return row[key] + " %";

      case "creator":
        return row[key];
    }
    unreachable(key);
  };

  return (
    <StyledContainer>
      <Spacer y={1} />
      <StyledHeader>
        <TextCapitalized>{t("pricing.select.company")}</TextCapitalized>
        <SelectDS
          label={""}
          value={valueOptionCompany}
          options={optionCompany}
          onChange={(selectedOption) =>
            setCompanySelected(selectedOption ? selectedOption.value : "")
          }
          error={
            (displayError &&
              companySelected === "" &&
              t("pricing.select.company.error")) ||
            undefined
          }
          allWidth
        />
        <TextCapitalized>{t("pricing.input.pricePerCard")}</TextCapitalized>
        <NumericInputDS
          value={pricePerCard}
          update={(value) =>
            setPricePerCard(value !== "" && value > 100 ? 100 : value)
          }
          floatNumber
        />
        <TextCapitalized>
          {t("pricing.input.percentagePerTransaction")}
        </TextCapitalized>
        <NumericInputDS
          value={percentagePerTransaction}
          update={(value) =>
            setPercentagePerTransaction(
              value !== "" && value > 100 ? 100 : value,
            )
          }
          floatNumber
        />
        <ButtonDS
          format="hug"
          buttonType="primary"
          text={t("pricing.button.create")}
          onClick={clickToSubmit}
          disabled={!isValidSubmission}
        />
      </StyledHeader>
      <Spacer y={2} />
      <TableDS<keyof PricingsList, PricingsList>
        data={pricingsList}
        headers={headers}
        render={render}
      />
      <Spacer y={3} />
    </StyledContainer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
`;
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
