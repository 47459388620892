import {
  ButtonDS,
  CheckBox,
  NumericInputDS,
  PageDS,
  SelectFuel,
  Spacer,
  ToastContainer,
  dateFormatterDayMonthYearAndHourMinute2Digits,
  triggerToast,
} from "@qivia/ui";
import { TextCapitalized } from "@qivia/ui/src/designSystem/components/text/TextCapitalized";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { VerificationStatusType } from "./supportingDocumentsApi";
import {
  getSupportingDocumentAsync,
  selectGetSupportingDocument,
  selectIsSupportingDocumentPdf,
  selectSupportingDocumentDataMessageError,
  selectTransactionsForVerificationList,
  selectUpdateSupportingDocumentStatus,
  supportingDocumentsVerificationInProgressAsync,
  updateSupportingDocumentDataAsync,
} from "./supportingDocumentsSlice";
import { selectAdminUser } from "./homeSlice";

type SupportingDocumentRouteParams = { id: string };

export const SupportingDocument = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<SupportingDocumentRouteParams>();
  const adminUser = useAppSelector(selectAdminUser);

  const currentSupportingDocument = useAppSelector(selectGetSupportingDocument);
  const isCurrentSupportingDocumentPdf = useAppSelector(
    selectIsSupportingDocumentPdf,
  );
  const transactionsForVerificationList = useAppSelector(
    selectTransactionsForVerificationList,
  );

  const updateSupportingDocumentDataMessageError = useAppSelector(
    selectSupportingDocumentDataMessageError,
  );
  const updateSupportingDocumentStatus = useAppSelector(
    selectUpdateSupportingDocumentStatus,
  );

  const transaction = useMemo(
    () =>
      transactionsForVerificationList.find((transaction) => {
        return transaction.uuid === params.id;
      }),
    [params.id, transactionsForVerificationList],
  );

  const [displayError, setDisplayError] = useState<boolean>(false);
  const [verificationStatus, setVerificationStatus] =
    useState<VerificationStatusType | null>(null);
  const [quantity, setQuantity] = useState<number | "">(
    transaction?.quantity ?? "",
  );
  const [pricePerLiter, setPricePerLiter] = useState<number | "">(
    transaction?.pricePerLiter ?? "",
  );
  const [fuel, setFuel] = useState<string | null>(transaction?.fuel ?? null);

  const isFuel = useMemo(() => {
    return (
      transaction &&
      (transaction.category === "FUEL_AUTOMATON" ||
        transaction.category === "SERVICE_STATION")
    );
  }, [transaction]);

  const resetValue = useCallback(() => {
    setQuantity("");
    setPricePerLiter("");
    setFuel(null);
    setVerificationStatus(null);
  }, []);

  const isCurrentSupportingDocumentDataValidForSubmit = useCallback(() => {
    const isFullData = quantity && pricePerLiter && fuel;
    if (isFullData && verificationStatus) {
      return false;
    }
    return isFullData || !!verificationStatus;
  }, [fuel, pricePerLiter, quantity, verificationStatus]);

  useEffect(() => {
    if (!transaction && transactionsForVerificationList.length > 0) {
      navigate(
        `/home/supporting_documents/${transactionsForVerificationList[0].uuid}`,
      );
    }
  }, [navigate, transaction, transactionsForVerificationList]);

  useEffect(() => {
    if (
      params.id === undefined ||
      transactionsForVerificationList.length === 0
    ) {
      return navigate("/home/supporting_documents");
    }
    void dispatch(getSupportingDocumentAsync(params.id));
  }, [dispatch, navigate, params.id, transactionsForVerificationList.length]);

  useEffect(() => {
    if (updateSupportingDocumentDataMessageError)
      triggerToast(t("supportingDocument.errorMessage"), "error");
  }, [t, updateSupportingDocumentDataMessageError]);

  useEffect(() => {
    if (updateSupportingDocumentStatus === "success") {
      triggerToast(t("supportingDocument.update.success") || "", "valid");
    } else if (updateSupportingDocumentStatus === "failed") {
      triggerToast(t("supportingDocument.update.failure") || "", "error");
    }
  }, [updateSupportingDocumentStatus, t, dispatch]);

  const close = useCallback(() => {
    navigate("/home/supporting_documents");
  }, [navigate]);

  const submit = useCallback(() => {
    if (!currentSupportingDocument) {
      return;
    }
    if (!isCurrentSupportingDocumentDataValidForSubmit()) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      void dispatch(
        updateSupportingDocumentDataAsync({
          quantity: quantity === "" ? null : quantity,
          pricePerLiter: pricePerLiter === "" ? null : pricePerLiter,
          fuel,
          verificationStatus: verificationStatus ?? "VERIFIED",
          supportingDocumentUuid: currentSupportingDocument.uuid,
        }),
      );
      void dispatch(
        supportingDocumentsVerificationInProgressAsync({
          userName: adminUser?.name ?? "",
        }),
      );
      resetValue();
    }
  }, [
    adminUser?.name,
    currentSupportingDocument,
    dispatch,
    fuel,
    isCurrentSupportingDocumentDataValidForSubmit,
    pricePerLiter,
    quantity,
    resetValue,
    verificationStatus,
  ]);

  if (!currentSupportingDocument || !transaction) {
    return;
  }

  return (
    <PageDS
      withoutSupport={true}
      toaster={<ToastContainer />}
      title={""}
      titleElement={{
        closeButtonAction: close,
        button: (
          <ButtonDS
            disabled={!isCurrentSupportingDocumentDataValidForSubmit()}
            text={t("supportingDocument.submit")}
            format={"hug"}
            buttonType={"primary"}
            onClick={submit}
          />
        ),
      }}
    >
      <StyledContainer>
        <StyledLeftColumn>
          <StyledFixedColumn>
            <TextCapitalized>
              {t("supportingDocument.category")}
              {t(`supportingDocuments.category.${transaction.category}`)}
            </TextCapitalized>
            <Spacer y={2} />
            <TextCapitalized>
              {t("supportingDocument.amount")}
              {transaction.amount.value / 100 +
                " " +
                transaction.amount.currency}
            </TextCapitalized>
            <Spacer y={2} />
            <TextCapitalized>
              {t("supportingDocument.executionDate")}
              {dateFormatterDayMonthYearAndHourMinute2Digits(
                new Date(transaction.executionDate),
              )}
            </TextCapitalized>
            <Spacer y={2} />
            <TextCapitalized>
              {t("supportingDocument.merchantName")}
              {transaction.merchantName}
            </TextCapitalized>
            <Spacer y={2} />
            <TextCapitalized>
              {t("supportingDocument.merchantLocation")}
              {transaction.merchantCity}, {transaction.merchantCountry}
            </TextCapitalized>
            <Spacer y={2} />
            {!isFuel ? (
              <>
                <CheckBox
                  text={t("supportingDocument.verified")}
                  onClick={() =>
                    verificationStatus === "VERIFIED"
                      ? setVerificationStatus(null)
                      : setVerificationStatus("VERIFIED")
                  }
                  state={
                    verificationStatus === "VERIFIED" ? "CHECKED" : "UNCHECKED"
                  }
                />
                <Spacer y={1} />
              </>
            ) : (
              <>
                <TextCapitalized>
                  {t("supportingDocument.fuel")}
                </TextCapitalized>
                <SelectFuel
                  value={fuel}
                  onChange={(value) => setFuel(value)}
                  error={displayError && !fuel && !verificationStatus}
                />
                <Spacer y={1} />
                <TextCapitalized>
                  {t("supportingDocument.quantity")}
                </TextCapitalized>
                <NumericInputDS
                  value={quantity}
                  update={(value) => setQuantity(value)}
                  floatNumber
                  adornent={"L"}
                  onKeyDown={(e) => e.key === "Enter" && submit()}
                  error={
                    (displayError &&
                      quantity === "" &&
                      !verificationStatus &&
                      t("supportingDocuments.emptyInput")) ||
                    undefined
                  }
                />

                <TextCapitalized>
                  {t("supportingDocument.pricePerLiter")}
                </TextCapitalized>
                <NumericInputDS
                  value={pricePerLiter}
                  update={(value) => setPricePerLiter(value)}
                  floatNumber
                  adornent={t("supportingDocument.currency")}
                  onKeyDown={(e) => e.key === "Enter" && submit()}
                  error={
                    (displayError &&
                      pricePerLiter === "" &&
                      !verificationStatus &&
                      t("supportingDocuments.emptyInput")) ||
                    (quantity < pricePerLiter &&
                      t(
                        "supportingDocuments.pricePerLiterSuperiorToQuantity",
                      )) ||
                    undefined
                  }
                />
              </>
            )}
            <CheckBox
              text={t("supportingDocument.unreadable")}
              onClick={() =>
                verificationStatus === "UNREADABLE"
                  ? setVerificationStatus(null)
                  : setVerificationStatus("UNREADABLE")
              }
              state={
                verificationStatus === "UNREADABLE" ? "CHECKED" : "UNCHECKED"
              }
            />
            <Spacer y={1} />
            <CheckBox
              text={t("supportingDocument.missing")}
              onClick={() =>
                verificationStatus === "MISSING"
                  ? setVerificationStatus(null)
                  : setVerificationStatus("MISSING")
              }
              state={verificationStatus === "MISSING" ? "CHECKED" : "UNCHECKED"}
            />
          </StyledFixedColumn>
        </StyledLeftColumn>
        <Spacer x={1} />
        {isCurrentSupportingDocumentPdf ? (
          <StyledIframe>
            <iframe
              src={currentSupportingDocument.presignedUrl}
              title={currentSupportingDocument.fileName}
            />
          </StyledIframe>
        ) : (
          <StyledRightColumn>
            <StyledImage src={currentSupportingDocument.presignedUrl} />
          </StyledRightColumn>
        )}
      </StyledContainer>
    </PageDS>
  );
};

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
`;

const StyledFixedColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  max-width: 40%;
  width: 100%;
`;

const StyledLeftColumn = styled.div`
  width: 50%;
`;

const StyledImage = styled.img`
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
`;

const StyledRightColumn = styled.div`
  max-width: 50%;
  width: fit-content;
  height: fit-content;
`;

const StyledIframe = styled.div`
  width: 100%;
  max-width: 50%;
  height: 100vh;
  iframe {
    width: 100%;
    height: 100%;
  }
`;
