import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  getTransactionsForVerificationAsync,
  selectTransactionsForVerificationList,
  selectTransactionsForVerificationStatus,
} from "./supportingDocumentsSlice";
import { useCallback, useEffect } from "react";
import {
  Page,
  Spacer,
  TableDS,
  TextCapitalized,
  ToastContainer,
  dateFormatterDayLongMonthYearHourMinuteSecond,
  unreachable,
} from "@qivia/ui";
import {
  TransactionsForVerificationListRow,
  TransactionsForVerificationListRowDisplay,
} from "./supportingDocumentsApi";
import { useNavigate, useParams } from "react-router-dom";
import { SupportingDocument } from "./supportingDocument";

export const SupportingDocuments = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const transactionsForVerificationList = useAppSelector(
    selectTransactionsForVerificationList,
  );

  const transactionsDisplayed: TransactionsForVerificationListRowDisplay[] =
    transactionsForVerificationList.map((t) => {
      return {
        uuid: t.uuid,
        category: t.category,
        company: t.company,
        executionDate: t.executionDate,
      };
    });
  const transactionsForVerificationStatus = useAppSelector(
    selectTransactionsForVerificationStatus,
  );

  useEffect(() => {
    if (transactionsForVerificationStatus === "idle") {
      void dispatch(getTransactionsForVerificationAsync());
    }
  }, [dispatch, transactionsForVerificationStatus]);

  const getSupportingDocumentData = useCallback(
    (id: string) => {
      navigate(`/home/supporting_documents/${id}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (params.id) {
      navigate(`/home/supporting_documents/${params.id}`);
    }
  }, [navigate, params.id]);

  const headers = {
    uuid: {
      text: t("supportingDocuments.column.uuid"),
    },
    company: {
      text: t("supportingDocuments.column.company"),
    },
    category: {
      text: t("supportingDocuments.column.category"),
    },
    executionDate: {
      text: t("supportingDocuments.column.date"),
    },
  };

  const render =
    (row: TransactionsForVerificationListRow) =>
    (key: keyof TransactionsForVerificationListRowDisplay) => {
      switch (key) {
        case "executionDate":
          return dateFormatterDayLongMonthYearHourMinuteSecond(
            new Date(row[key]),
          );
        case "category":
          return (
            <TextCapitalized>
              {t(`supportingDocuments.category.${row[key]}`)}
            </TextCapitalized>
          );
        case "uuid":
        case "company":
          return row[key];
      }
      unreachable(key);
    };

  return (
    <>
      {params.id ? (
        <SupportingDocument />
      ) : (
        <Page
          title={t("supportingDocuments.title")}
          pageName={"supportingDocuments"}
          toaster={<ToastContainer />}
        >
          <TextCapitalized>{t("supportingDocuments.subTitle")}</TextCapitalized>
          <Spacer y={3} />
          <TableDS<
            keyof TransactionsForVerificationListRowDisplay,
            TransactionsForVerificationListRowDisplay
          >
            data={transactionsDisplayed}
            headers={headers}
            render={render}
            onClickRow={(transaction) =>
              getSupportingDocumentData(transaction.uuid)
            }
          />
          <Spacer y={3} />
        </Page>
      )}
    </>
  );
};
